// Variables
// =================================

$container-width:1118px;

// colors
$primary-theme-color:#00c2e6;
$color-grey:#F3F6FB;
$color-light-grey:#8896A5;
$color-xlight-grey:#B1BDC9;
$color-white:#fff;
$color-black:#000;
$color-red:#E60047;
$grey-disabled: #c8c7c9;
$success-button-bg-color:#30C62E;
$danger-button-bg-color:#F26765;
$warning-button-bg-color:#F5A623;

$bg-grey:#f3f6fb;

$tags-background-color:#E1E6EF;

// inputs
$input-border-color:#d0d0d0;
$radio-button-color:#ced4da;

// Fonts
$font-grotesk-Regular:'HKGrotesk-Regular',
Arial,
"sans-serif";
$font-grotesk-bold:'HKGrotesk-Bold',
Arial,
"sans-serif";

$header-bg-color:$color-white;
$nav-item-color:$color-black;

$font-size-small:12px;
$font-size-default:14px;
$font-size-md:16px;

// Margin & Padding
$blocks-inside-padding:20px;

// Shadow
$blocks-shadow:0px 0px 10px rgba(162, 180, 196, 0.16);
$blocks-shadow-dark:0px 0px 10px rgba(162, 180, 196, 0.50);

// Radius
$blocks-radius:4px;

// Buttons

$btn-outline-dark:$color-black;

$primary-button-bg-color:$primary-theme-color;
$primary-button-shadow:0px 3px 10px rgba(0, 194, 230, 0.32);

$primary-button-outline-color:$primary-theme-color;

$primary-button-hover-outline-color:#08d8ff;
$primary-button-hover-outline-shadow:0px 3px 10px rgba(8, 216, 255, 0.32);

$primary-button-active-outline-color:#08afce;
$primary-button-active-outline-shadow:0px 3px 10px rgba(8, 175, 216, 0.40);

$success-button-bg-color:$success-button-bg-color;
$success-button-shadow:0px 3px 10px rgba(48, 198, 46, 0.32);

$danger-button-bg-color:$danger-button-bg-color;
$danger-button-shadow:0px 3px 10px rgba(242, 103, 101, 0.32);

$warning-button-bg-color:$warning-button-bg-color;
//$warning-button-shadow:0px 3px 10px rgba(242, 103, 101, 0.32);

// Headings
$h1-font-size:18px;
$h2-font-size:14px;
$h3-font-size:14px;

// mixins
@mixin box-shadow($property) {
    -webkit-box-shadow: $property;
    -ms-box-shadow: $property;
    box-shadow: $property;
}

@mixin border-radius($radius-property) {
    -webkit-border-radius: $radius-property;
    -ms-border-radius: $radius-property;
    border-radius: $radius-property;
}

body {
    overflow-x: hidden;
    height: 100%
}

html {
    height: 100%;
}

#root {
    height: 100%;
}

.footer-container {
    margin-top: auto;
}

.app {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    min-height: 100%;
    position: relative;
    transition: margin 0.3s ease-out 0s;
}

.color-blue {
    color: $primary-theme-color
}



.minWidth-200 {
    min-width: 200px !important;
}

// Margins

.m-top0 {
    margin-top: 0
}

.mg-left-15 {
    margin-left: 15px
}

.m-bottom0 {
    margin-bottom: 0
}

.mb-40 {
    margin-bottom: 40px
}

.ml-380 {
    margin-left: 380px
}

.mt-50 {
    margin-top: 50px
}

.mt-45 {
    margin-top: 45px;
}

.ml-80 {
    margin-left: 80px;
}

.mr-30 {
    margin-right: 30px;
}

// Paddings
.ptop0 {
    padding-top: 0px !important;
}


.ptop10 {
    padding-top: 10px
}

.pt-50 {
    padding-top: 50px;
}

.pt-90 {
    padding-top: 90px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-40 {
    padding-top: 40px;
}

.ptop15 {
    padding-top: 10px
}

.margin-top-20 {
    margin-top: 20px;
}

.p0 {
    padding: 0px;
}

// // Global elements customizaation
// .transparent-btn {
//     background: none;
//     color: #03c2e6;
//     border: 0;
//     padding: 0;
//     margin-bottom: 15px;
// }

// .transparent-btn:hover,
// .transparent-btn:focus {
//     background: none;
//     box-shadow: none;
//     color: #03c2e6;
// }

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: none !important
}

.form-control {
    border: 1px solid #d0d0d0;
    height: 30px;
}

.form-control:focus {
    border-color: $primary-theme-color;
    @include box-shadow(none);
}

.form-group {
    input {
        font-family: $font-grotesk-Regular;
        font-size: $font-size-default;
    }
}

// Buttons
.btn {
    font-size: 16px;
    font-family: $font-grotesk-Regular;
    @include border-radius($blocks-radius);
}

.btn-default {
    background: #fff;
    @include box-shadow($blocks-shadow !important);
}

// btn primary
.btn.btn-primary {
    color: #fff;
    border: 0;
    background: $primary-button-bg-color;
    @include box-shadow($primary-button-shadow !important);

    &:focus,
    &.focus,
    &:hover {
        background: rgba(0, 196, 230, 1);
        background: -moz-linear-gradient(top, rgba(0, 196, 230, 1) 0%, rgba(8, 218, 255, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 196, 230, 1)), color-stop(100%, rgba(8, 218, 255, 1)));
        background: -webkit-linear-gradient(top, rgba(0, 196, 230, 1) 0%, rgba(8, 218, 255, 1) 100%);
        background: -o-linear-gradient(top, rgba(0, 196, 230, 1) 0%, rgba(8, 218, 255, 1) 100%);
        background: -ms-linear-gradient(top, rgba(0, 196, 230, 1) 0%, rgba(8, 218, 255, 1) 100%);
        background: linear-gradient(to bottom, rgba(0, 196, 230, 1) 0%, rgba(8, 218, 255, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00c4e6', endColorstr='#08daff', GradientType=0);
        border: 0;
        color: $color-white
    }

    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active {
        background: rgba(0, 196, 230, 1);
        background: -moz-linear-gradient(top, rgba(0, 196, 230, 1) 0%, rgba(8, 176, 206, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 196, 230, 1)), color-stop(100%, rgba(8, 176, 206, 1)));
        background: -webkit-linear-gradient(top, rgba(0, 196, 230, 1) 0%, rgba(8, 176, 206, 1) 100%);
        background: -o-linear-gradient(top, rgba(0, 196, 230, 1) 0%, rgba(8, 176, 206, 1) 100%);
        background: -ms-linear-gradient(top, rgba(0, 196, 230, 1) 0%, rgba(8, 176, 206, 1) 100%);
        background: linear-gradient(to bottom, rgba(0, 196, 230, 1) 0%, rgba(8, 176, 206, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00c4e6', endColorstr='#08b0ce', GradientType=0);
        border: 0;
        color: $color-white
    }

}

.btn.btn-outline-primary {
    color: #000 !important;
    border-color: $primary-theme-color !important;
    background: #fff !important;

    &:hover {
        background: #fff;
        color: #000;
        border-color: $primary-button-hover-outline-color;
        @include box-shadow($primary-button-hover-outline-shadow);
    }

    &:focus,
    &:active,
    &:visited,
    &:not(:disabled):not(.disabled):active {
        background: #fff;
        color: #000;
        border-color: $primary-button-active-outline-color;
        @include box-shadow($primary-button-active-outline-shadow);
    }
}

// btn success

.btn.btn-success {
    color: #fff;
    border: 0;
    background: $success-button-bg-color;
    @include box-shadow($success-button-shadow !important);

    &:focus,
    &.focus,
    &:hover {
        background: rgba(48, 198, 46, 1);
        background: -moz-linear-gradient(top, rgba(48, 198, 46, 1) 0%, rgba(53, 213, 50, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(48, 198, 46, 1)), color-stop(100%, rgba(53, 213, 50, 1)));
        background: -webkit-linear-gradient(top, rgba(48, 198, 46, 1) 0%, rgba(53, 213, 50, 1) 100%);
        background: -o-linear-gradient(top, rgba(48, 198, 46, 1) 0%, rgba(53, 213, 50, 1) 100%);
        background: -ms-linear-gradient(top, rgba(48, 198, 46, 1) 0%, rgba(53, 213, 50, 1) 100%);
        background: linear-gradient(to bottom, rgba(48, 198, 46, 1) 0%, rgba(53, 213, 50, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#30c62e', endColorstr='#35d532', GradientType=0);
        border: 0;
        color: $color-white
    }

    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active {
        background: rgba(48, 198, 46, 1);
        background: -moz-linear-gradient(top, rgba(48, 198, 46, 1) 0%, rgba(45, 165, 43, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(48, 198, 46, 1)), color-stop(100%, rgba(45, 165, 43, 1)));
        background: -webkit-linear-gradient(top, rgba(48, 198, 46, 1) 0%, rgba(45, 165, 43, 1) 100%);
        background: -o-linear-gradient(top, rgba(48, 198, 46, 1) 0%, rgba(45, 165, 43, 1) 100%);
        background: -ms-linear-gradient(top, rgba(48, 198, 46, 1) 0%, rgba(45, 165, 43, 1) 100%);
        background: linear-gradient(to bottom, rgba(48, 198, 46, 1) 0%, rgba(45, 165, 43, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#30c62e', endColorstr='#2da52b', GradientType=0);
        border: 0;
        color: $color-white
    }

}

// btn danger

.btn.btn-danger {
    color: #fff;
    border: 0;
    background: $danger-button-bg-color;
    @include box-shadow($danger-button-shadow !important);

    &:focus,
    &.focus,
    &:hover {
        background: rgba(242, 103, 101, 1);
        background: -moz-linear-gradient(top, rgba(242, 103, 101, 1) 0%, rgba(246, 141, 141, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(242, 103, 101, 1)), color-stop(100%, rgba(246, 141, 141, 1)));
        background: -webkit-linear-gradient(top, rgba(242, 103, 101, 1) 0%, rgba(246, 141, 141, 1) 100%);
        background: -o-linear-gradient(top, rgba(242, 103, 101, 1) 0%, rgba(246, 141, 141, 1) 100%);
        background: -ms-linear-gradient(top, rgba(242, 103, 101, 1) 0%, rgba(246, 141, 141, 1) 100%);
        background: linear-gradient(to bottom, rgba(242, 103, 101, 1) 0%, rgba(246, 141, 141, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f26765', endColorstr='#f68d8d', GradientType=0);
        border: 0;
        color: $color-white
    }

    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active {
        background: rgba(242, 103, 101, 1);
        background: -moz-linear-gradient(top, rgba(242, 103, 101, 1) 0%, rgba(213, 95, 93, 1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(242, 103, 101, 1)), color-stop(100%, rgba(213, 95, 93, 1)));
        background: -webkit-linear-gradient(top, rgba(242, 103, 101, 1) 0%, rgba(213, 95, 93, 1) 100%);
        background: -o-linear-gradient(top, rgba(242, 103, 101, 1) 0%, rgba(213, 95, 93, 1) 100%);
        background: -ms-linear-gradient(top, rgba(242, 103, 101, 1) 0%, rgba(213, 95, 93, 1) 100%);
        background: linear-gradient(to bottom, rgba(242, 103, 101, 1) 0%, rgba(213, 95, 93, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f26765', endColorstr='#d55f5d', GradientType=0);
        border: 0;
        color: $color-white
    }

}


// btn danger

.btn.btn-warning {
    color: #fff;
    border: 0;
    background: $warning-button-bg-color;
    //@include box-shadow($danger-button-shadow !important);

    &:focus,
    &.focus,
    &:hover {
        background: -moz-linear-gradient(280deg, rgba(245, 166, 35, 1) 0%, rgba(245, 197, 42, 1) 100%);
        /* ff3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(245, 166, 35, 1)), color-stop(100%, rgba(245, 197, 42, 1)));
        /* safari4+,chrome */
        background: -webkit-linear-gradient(280deg, rgba(245, 166, 35, 1) 0%, rgba(245, 197, 42, 1) 100%);
        /* safari5.1+,chrome10+ */
        background: -o-linear-gradient(280deg, rgba(245, 166, 35, 1) 0%, rgba(245, 197, 42, 1) 100%);
        /* opera 11.10+ */
        background: -ms-linear-gradient(280deg, rgba(245, 166, 35, 1) 0%, rgba(245, 197, 42, 1) 100%);
        /* ie10+ */
        background: linear-gradient(170deg, rgba(245, 166, 35, 1) 0%, rgba(245, 197, 42, 1) 100%);
        /* w3c */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5a623', endColorstr='#F5C52A', GradientType=0);
        /* ie6-9 */
        border: 0;
        color: $color-white
    }

    &:active,
    &.active,
    &:not(:disabled):not(.disabled):active {
        background: -moz-linear-gradient(280deg, rgba(245, 166, 35, 1) 0%, rgba(245, 131, 0, 1) 100%);
        /* ff3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(245, 166, 35, 1)), color-stop(100%, rgba(245, 131, 0, 1)));
        /* safari4+,chrome */
        background: -webkit-linear-gradient(280deg, rgba(245, 166, 35, 1) 0%, rgba(245, 131, 0, 1) 100%);
        /* safari5.1+,chrome10+ */
        background: -o-linear-gradient(280deg, rgba(245, 166, 35, 1) 0%, rgba(245, 131, 0, 1) 100%);
        /* opera 11.10+ */
        background: -ms-linear-gradient(280deg, rgba(245, 166, 35, 1) 0%, rgba(245, 131, 0, 1) 100%);
        /* ie10+ */
        background: linear-gradient(170deg, rgba(245, 166, 35, 1) 0%, rgba(245, 131, 0, 1) 100%);
        /* w3c */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5a623', endColorstr='#F58300', GradientType=0);
        /* ie6-9 */
        border: 0;
        color: $color-white
    }

}

.cancel-subs-btn.btn-secondary:not(:disabled):not(.disabled):active,
.cancel-subs-btn:focus,
.cancel-subs-btn:hover {
    color: #e23b39 !important;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

.switch-text.btn-secondary:not(:disabled):not(.disabled):active {
    color: #0f940d;
}

.show>.btn-outline-primary.dropdown-toggle {
    background: #fff;
    color: inherit;
    border-color: #00c2e6;
}

.show>.btn-outline-primary.dropdown-toggle:focus {
    @include box-shadow($primary-button-hover-outline-shadow);

}

.btn.btn-outline-dark {
    color: $btn-outline-dark;
    border-color: $btn-outline-dark;

    &:focus,
    &.focus,
    &:hover,
    &:active,
    &.active {
        background: $btn-outline-dark;
        border-color: $btn-outline-dark;
        color: $color-white;
        @include box-shadow(none !important)
    }
}

.btn-secondary {

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
        color: #00c2e6;
        background-color: transparent;
        border-color: transparent;
    }
}

.show>.btn-secondary.dropdown-toggle {
    color: #00c2e6;
    background-color: transparent;
    border-color: transparent;
}

.btn.btn-link {
    color: #000;
    font-size: 14px
}

button.dropdown-toggle {
    &:hover {
        text-decoration: none
    }
}


.btn-with-caret {
    min-width: 100px;
    padding-right: 11px;
    padding-left: 11px;
    height: 41px;
    text-align: left;

    &:after {
        border: 0;
        background: url(./img/caret-down.svg) no-repeat 0 0;
        width: 12px;
        height: 8px;
        vertical-align: middle;
        margin-left: 3px;
        margin-top: 9px;
        background-size: 15px 5px;
        float: right;
    }
}

a.link {
    color: $primary-theme-color;

    &:hover,
    &:focus,
    &:active {
        color: $primary-theme-color;
    }
}

// .error {
// border-color: #dc3545;
// }
// Icons
.icon {
    display: inline-block;
}

.other-filter {
    top: 6px !important;

}

.tooltip-icon {
    background: url(./img/icon-qus.svg) no-repeat 0 0;
    min-width: 16px;
    min-height: 16px;
    display: inline-block;
    background-size: 15px;
    position: relative;
    top: 3px;

    &:hover {
        opacity: 0.9;
    }
}

// tooltip button
.tooltip-content {
    font-size: 20px !important;
}

.tooltip-button-wrap {
    .text-tooltip {
        float: left;
        padding-right: 8px;

    }
}

.tooltip {
    font-size: 9px
}


//loader
.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.80);
    z-index: 1150;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;//$primary-theme-color;
}



// Dropdown

.dropdown-menu {
    font-size: $font-size-default;
    border: 0
}

.dropdown-item {
    padding: .65rem 1.0rem
}

.dropdown-toggle {
    font-size: $font-size-default;
}



// Lists

.icon-list {
    li {
        border: 0;
        padding: 0 0 0 32px;
        position: relative;
        margin-bottom: 10px;

        &.icon {
            &:after {
                background-repeat: no-repeat;
                content: "";
                position: absolute;
                left: 0;
                top: 4px;
                width: 22px;
                height: 14px;
                background-size: 21px 12px;

            }
        }

        .text-wrap {
            overflow: hidden;
            white-space: nowrap !important;
            text-overflow: ellipsis
        }
    }
}



.badge {
    font-size: 90%
}

.badge-light {
    color: $color-light-grey
}

// Download button

.download-icon-button {
    font-size: 12px;
    position: relative;
    background-color: transparent;
    border: 0px;
    color: #00c2e6;
    padding: 0px 0px 0px 18px;

    &:before {
        content: "";
        position: absolute;
        background: url(./img/download-icon.svg) no-repeat 0 0;
        width: 15px;
        height: 15px;
        background-size: 12px;
        left: 0;
        top: 0px;
    }
}

.download-icon-button {

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        color: #00c2e6;
        box-shadow: none;
        -webkit-box-shadow: none;
    }

}

// change this into scss for

// Progress bar

.progress-bar {
    background: $primary-theme-color
}


// // Header
// // =====================================
// header {
//     // max-width: $container-width;
//     margin: 0 auto;
//     padding-bottom: 0;
//     padding-top: 0;
//     align-items: center;
//     padding: 0px 15px;
// }

// span.user-name {
//     margin-right: 8px;
// }

// span.user-name+img {
//     width: 32px;
//     height: 32px;
//     border-radius: 100%;
// }

// .log-dropdown::after {
//     background-size: 10px !important;
//     margin-left: 10px !important;
// }

// .navbar-wrap {
//     background: $header-bg-color;
//     padding-top: 4px;
//     min-height: 60px;

//     .navbar-brand {
//         padding-top: 0
//     }

//     .navbar-light {
//         padding: 0px;

//         .navbar-nav .nav-link {
//             color: $nav-item-color;
//             font-weight: 400;

//             &:hover {
//                 color: $primary-theme-color
//             }
//         }
//     }
// }

// //  .highcharts-container {
// //     width:100% !important;
// //     height:100% !important;
// //  }
// .navbar-light .navbar-toggler {
//     color: #000;
//     border: 0;
//     padding: 0;
//     outline: 0 !important;
// }

// .btn.btn-sign-in {
//     padding: 0 20px;
//     height: 30px;
//     margin-top: 15px;
//     font-size: 14px;
//     white-space: nowrap;
// }

// Headings

h1,
h3,
h4,
h5 {
    font-family: $font-grotesk-bold
}

h1 {
    font-size: $h1-font-size
}

h2 {
    font-size: $h2-font-size
}

h3 {
    font-size: $h2-font-size
}

h4 {
    font-size: $font-size-default
}


.page-title {
    font-size: $h1-font-size;
    margin-top: 8px
}

.heading-with-icon {
    background: url(./img/icon-qus.svg) no-repeat 0 0;
    padding-left: 30px;
    color: $color-light-grey;
    min-height: 22px;
    line-height: 22px;

}

// .heading-without-icon {
//     color: $color-light-grey;
//     min-height: 22px;
//     line-height: 22px;
// }

// .tooltip-icon-for-heading {
//     background: url(./img/icon-qus.svg) no-repeat 0 0;
//     min-width: 22px;
//     min-height: 22px;
//     display: inline-block;
//     background-size: 22px;
//     position: relative;
//     top: 7px;
//     padding-left: 30px;
// }

// Globaly usable classses
.text-grey {
    color: $color-light-grey
}

// Modal custom style

.modal-header {
    font-family: $font-grotesk-bold;
    border: 0;
    padding-bottom: 1px;

    .modal-title {
        font-size: 18px;

        .tooltip-icon {
            margin-top: 4px;
        }
    }

}

.modal-header .close {
    outline: none
}

// main site content
.main-site-content {
    padding-top: 75px;
    padding-bottom: 20px
}

.btn-export-data {
    @include border-radius(4px !important);

    +.dropdown-menu .dropdown-item {
        a {
            font-size: $font-size-small;
            color: #000;
            text-decoration: none;
        }

        &:hover {
            a {
                color: #fff
            }
        }
    }
}

// Content
// ==================================

.search-and-topic-tensor {
    margin-top: 17px;
    margin-bottom: 17px;

    .search-block {
        background: #fff;
        @include box-shadow($blocks-shadow);
        @include border-radius($blocks-radius);
        box-shadow: $blocks-shadow;
        padding: 15px;
    }
}

.search-wrap {
    margin-bottom: 20px;

    .input-group-text {
        background: url(./img/icon-search.svg) no-repeat 15px 16px #fff;
    }

    .form-control {
        height: calc(1.5em + .75rem + 12px);
        border-radius: 4px !important;
        background: url(./img/icon-search.svg) no-repeat 15px 16px #fff;
        padding-left: 40px
    }

    .input-group-btn {
        padding-left: 10px;

        .btn {
            width: 160px;
            height: 48px;
        }
    }
}

.row.range-row {
    padding-top: 12px;
    padding-bottom: 4px;
}

// .slidecontainer {
//     padding-top: 5px;


//     .slidecontainer {
//         display: inline-block
//     }
// }

// .slidecontainer {
//     &.dots-wrap {
//         text-align: center;

//         span.dot {
//             @include border-radius(50%);
//             display: inline-block;
//             width: 7px;
//             height: 7px;
//             background: $primary-theme-color;
//             margin: 0px 10px;

//             &.primary-light {
//                 background: lighten($primary-theme-color, 25%)
//             }

//             &.primary-dark {
//                 background-color: darken($primary-theme-color, 15%);
//             }
//         }
//     }

//     &.letters-wrap {
//         text-align: center;

//         span.letter {
//             font-family: $font-grotesk-bold;
//             margin: 0 10px;

//             &.small {
//                 font-size: 10px
//             }

//             &.medium {
//                 font-size: 16px
//             }

//             &.large {
//                 font-size: 22px
//             }

//         }
//     }
// }

// // topic tensor
// .search-and-topic-tensor {
//     .topic-tensor {
//         background: #fff;
//         @include box-shadow($blocks-shadow);
//         @include border-radius($blocks-radius);
//         padding: $blocks-inside-padding;
//         padding-bottom: 1px
//     }
// }

// .range-slider {
//     display: inline-block
// }

// .tags {
//     img {
//         max-width: 100%
//     }
// }

// .range-row {
//     font-size: 14px
// }

// .ts-tag-clouds {
//     height: 200px;
// }

// Search Results Table
// ====================================
// Filters
.report-page-filters {
    .btn-default {
        min-width: 100px !important;
    }
}

.filters-wrap {
    padding-bottom: 10px;

    .btn-dropdown-white {
        color: #000 !important;
        border: 0;
        background: #fff !important;

        &:hover,
        &:focus,
        &:active,
        &.active {
            background: $primary-theme-color !important;
            color: #fff !important;
            @include box-shadow($primary-button-shadow !important);
        }

    }

    .btn-default {
        margin-right: 14px;
        margin-bottom: 5px;
        margin-top: 5px;
        min-width: 135px;
        padding-right: 11px;
        padding-left: 11px;
        height: 41px;
        text-align: left;

        &:after {
            border: 0;
            background: url(./img/caret-down.svg) no-repeat 0 0;
            width: 15px;
            height: 8px;
            vertical-align: middle;
            margin-left: 3px;
            margin-top: 9px;
            background-size: 15px 5px;
            float: right;
        }

        &.settings.btn-dropdown-white {
            min-width: 41px;
            height: 41px;
            margin-right: 0;
            background: url(./img/icon-three-dots.svg) no-repeat 13px 18px #fff !important;
            @include border-radius($blocks-radius !important)
        }

        &:hover,
        &:focus,
        &:active,
        &.active {
            &:after {
                background: url(./img/caret-down-white.svg) no-repeat 0 0;
                background-size: 15px 5px;
            }
        }
    }

}

.filters-wrap {
    .dot-filter-width {
        width: 41px !important;
    }
}


.custom-dropdown-column {
    padding: 1.2rem 1.0rem;

    &.width-lg {
        width: 630px;

        @media only screen and (max-width:767px) {
            max-width: 630px;
            width: 100%;
        }
    }

    &.width-lg-1 {
        width: 410px;

        @media only screen and (max-width:767px) {
            max-width: 410px;
            width: 100%;
        }
    }

    &.width-md {
        width: 202px;
    }

    &.width-md-2 {
        width: 305px;
    }

    &.width-md-3 {
        width: 225px;
    }

    .picky__input,
    select {
        font-size: $font-size-small;
        height: 30px;
        border-color: $input-border-color;
        outline: none;
        @include border-radius($blocks-radius);

        &:after {
            border: 0;
            background: url(./img/icon-search.svg) no-repeat right 0;
            width: 13px;
            height: 15px;
            top: 7px;
            right: 8px;
            background-size: 10px 15px;

        }

        &:focus {
            border-color: $primary-theme-color
        }
    }

    .picky__dropdown {
        @include box-shadow($blocks-shadow-dark);
        @include border-radius($blocks-radius);
        top: 30px;
        color: $color-xlight-grey;
        font-size: $font-size-small;
    }

    .picky__filter__input {
        border-color: $input-border-color;
        color: $color-xlight-grey
    }

    .col {
        display: inline-block;
    }

    .selected-tags-bottom {
        padding-top: 27px;

        .form-control {
            font-size: $font-size-small;
        }

        .active-filters-wrap.col-md-6 {
            padding-right: 0
        }
    }

    .head {
        font-size: $font-size-small;
        position: relative;

        &.icon-audience,
        &.icon-influencer {
            padding-left: 20px;

            &:after {
                content: "";
                width: 15px;
                height: 13px;
                display: inline-block;
                left: 0;
                top: -1px;
                position: absolute;
            }
        }

        &.icon-audience:after {
            background: url(./img/001-audience.svg) no-repeat 0 0;
        }

        &.icon-influencer:after {
            background: url(./img/002-megaphone.svg) no-repeat 0 0;
            background-size: 16px 11px;
        }
    }
}

// active filters
// .active-filters {
//     ul {
//         margin-bottom: 5px
//     }

//     li {
//         cursor: pointer;
//         position: relative;
//         padding-right: 30px;
//         background: $tags-background-color;
//         display: block;
//         min-height: 35px;
//         margin-right: 10px;
//         margin-bottom: 5px;
//         @include border-radius($blocks-radius);
//         padding: 5px 22px 5px 28px;
//         width: 100%;
//         max-width: 205px;

//         &.active-icon-audience,
//         &.active-icon-influencer {
//             &:after {
//                 content: "";
//                 width: 15px;
//                 height: 13px;
//                 display: inline-block;
//                 left: 10px;
//                 top: 8px;
//                 position: absolute;
//             }
//         }

//         &.active-icon-audience:after {
//             background: url(./img/001-audience.svg) no-repeat 0 0;
//         }

//         &.active-icon-influencer:after {
//             background: url(./img/002-megaphone.svg) no-repeat 0 1px;
//             background-size: 16px 11px;
//         }

//         .close {
//             opacity: 1;
//             text-shadow: none;
//             position: absolute;
//             top: 5px;
//             right: 8px;
//         }

//         span {
//             text-overflow: ellipsis;
//             /* Required for text-overflow to do anything */
//             white-space: nowrap;
//             overflow: hidden;

//         }

//         .li-audience {
//             position: relative;
//             background-color: #E1E6EF;

//             margin-right: 0;


//         }

//         .li-audience+.form-group {
//             position: absolute;
//             top: 0;
//             right: 0;
//             width: 80px;
//             margin: 0;
//         }
//     }

//     &.inside {
//         li {
//             cursor: text;
//             margin-right: 5px;
//             padding: 5px;
//             padding-right: 15px;
//             font-size: $font-size-small;
//             margin-bottom: 5px;

//             .close {
//                 right: 5px;
//                 font-size: 16px;
//             }
//         }
//     }
// }

// .left-audience {
//     .active-filters-wrap {
//         .active-filters.inside {
//             li {
//                 margin-right: 0;
//                 padding: 0 90px 0 0;
//                 background-color: transparent;

//                 .p-0 {
//                     padding: 0 !important;
//                 }
//             }
//         }
//     }
// }

// .gender-list {
//     margin-bottom: 0;

//     li {
//         margin-bottom: 6px;

//         &:last-child {
//             margin-bottom: 0
//         }

//         .form-check {
//             padding-left: 0
//         }
//     }
// }

// .settings-list {
//     margin-bottom: 0;
//     padding: .5rem 0;

//     li {
//         padding: .35rem 0;

//         /*padding:.35rem 1rem;*/
//         .form-check {
//             padding-left: 0;
//             font-size: $font-size-small
//         }
//     }
// }

// .other-filters {
//     li {
//         padding: .35rem 1rem;
//     }
// }

// custom stype for react-select-component
.multi-select-placeholder {
    color: hsl(0, 0%, 50%);
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
}

.css-1rhbuit-multiValue {
    background-color: white !important;
    border-radius: 0px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    margin: 0px !important;
    min-width: 0;
    box-sizing: border-box;
    display: none !important;
}

.css-1okebmr-indicatorSeparator {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 0px !important;
    box-sizing: border-box;
}

.css-1pahdxg-control {
    border-color: #00c2e6 !important;
    box-shadow: 0 0 0 1px #00c2e6 !important;
}

// Custom radio button style

.radio-container {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// Hide the browser's default radio button
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: $radio-button-color;
    border-radius: 50%;
}

// On mouse-over, add a grey background color
.container:hover input~.checkmark {
    background-color: $radio-button-color;
    ;
}

// When the radio button is checked, add a blue background
.radio-container input:checked~.checkmark {
    background-color: $primary-theme-color;
}

// Create the indicator (the dot/circle - hidden when not checked)
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

// Show the indicator (dot/circle) when checked
.radio-container input:checked~.checkmark:after {
    display: block;
}

// Style the indicator (dot/circle)
.radio-container .checkmark:after {
    top: 4px;
    left: 4px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: white;
}

// Custom checkbox style

.styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    margin-left: 0;

    &+label {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 24px;
    }

    // Box.
    &+label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 14px;
        height: 14px;
        background: $radio-button-color;
        position: absolute;
        left: 0;
        top: 5px;
        @include border-radius(2px)
    }

    // // Box hover
    // &:hover + label:before {

    // }

    // // Box focus
    // &:focus + label:before {

    // }

    // Box checked
    &:checked+label:before {
        background: $primary-theme-color;

    }

    // Disabled state label.
    &:disabled+label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled+label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked+label:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 10px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
        transform: rotate(45deg);
    }
}

// Edit and sort buttons
// .edit-and-sort-buttons {
//     margin-bottom: 11px;
// }

// .gear-edit-columns {
//     .btn-default.gear {
//         width: 41px;
//         height: 41px;
//         padding: 9px;
//         cursor: pointer;

//         &:hover,
//         &:focus,
//         &:active {
//             @include box-shadow($primary-button-shadow !important)
//         }
//     }

//     .edit-col-list {
//         margin-bottom: 0;
//         padding: .5rem 0;

//         li {
//             padding: .35rem 1.0rem;

//             .form-check {
//                 padding-left: 0
//             }
//         }
//     }
// }

// .sortby-button {
//     margin-top: 3px;

//     &.dropdown-toggle::after {
//         border: 0;
//         background: url(./img/caret-down.svg) no-repeat 0 0;
//         width: 20px;
//         height: 8px;
//         vertical-align: middle;
//         margin-left: 15px;
//     }
// }

// .change-plan-dropdown {
//     margin-top: 3px;

//     &.dropdown-toggle::after {
//         border: 0;
//         background: url(./img/caret-down.svg) no-repeat 0 0;
//         width: 20px;
//         height: 8px;
//         vertical-align: middle;
//         margin-left: 15px;
//         z-index: 999999;
//     }
// }

.ddmenu-col {
    @include box-shadow($blocks-shadow-dark);
    @include border-radius($blocks-radius);

    .dropdown-item {
        &:hover {
            background: $primary-theme-color;
            color: #fff
        }
    }
}

.mini-select {
    max-width: 70px;
    display: inline-block;
    width: 100%;

    select {
        font-size: 12px
    }
}


.select-field-col {
    display: inline-block;
    max-width: 80px;
    width: 100%;
    margin-right: 10px;

    &.full {
        max-width: 100%;
    }

    select {
        font-size: 12px;
    }

    &.last {
        margin-right: 0
    }
}

// Table

.search-results-wrap {
    .table-outer {
        @include box-shadow($blocks-shadow);
        @include border-radius($blocks-radius);
        background: #fff;
        font-size: 14px;
        padding: 15px 40px 25px 25px
    }

    .no-left-right-padding {
        padding: 15px 0px 25px 0px
    }

    .table {
        thead th {
            font-family: $font-grotesk-bold;
            vertical-align: middle
        }

        tbody td {
            vertical-align: middle;
            padding-top: 8px;
            padding-bottom: 8px;

            .btn-outline-primary {
                // height: 34px;
                // line-height: 34px;
                // padding: 0 10px;
                // // width: 100%;
                // width: 110px;
                // max-width: 120px;
                max-height: 105px;
                line-height: 34px;
                padding: 0 10px;
                width: 100%;
            }
        }
    }

    .table-hover tbody tr:hover {
        background-color: #f3f6fb;
    }
}

.search-results-wrap {
    .media-left {
        margin-right: 10px;

        img {
            width: 50px;
            height: 50px;
            @include border-radius(50%)
        }
    }

    .username-col h4.media-heading {
        font-size: 14px;
        margin-bottom: 0;
        // cursor: pointer;
    }

}

.icon-arrow-up,
.icon-arrow-down {
    width: 20px;
    height: 10px;
    display: inline-block;

}

.icon-arrow-up {
    background: url(./img/icon-arrow-up.svg) no-repeat center 0;
}

.icon-arrow-down {
    background: url(./img/icon-arrow-down.svg) no-repeat center 0;
}

.us-flag-icon {
    width: 32px;
    height: 18px;
    display: inline-block;
    background: url(./img/us-flag-icon.svg) no-repeat center 0;
    position: relative;
    bottom: -4px;
}

// Footer
// =====================================
footer {
    background: #fff;
    padding: 26px 0;

    .copyright {
        color: $color-xlight-grey;
        text-align: center;
        font-size: 12px;
        padding-top: 3px;
    }
}

.footer-menu-items {
    margin-right: 0px;
    text-align: center;

    ul {
        margin-bottom: 0;
    }

    li {
        margin-left: 10px;
        margin-right: 10px;

        a {
            color: #000;
        }
    }
}

// Auth pages
// =====================================
.auth-wrap {

    background-color: #fff;
    //background-image: url(img/login-bg.svg);
    background-repeat: repeat;
    background-position: center top;

}

.paddingTop {
    background-position: center 70px;
}

.page-head {
    text-align: center;
    padding-bottom: 25px;
    padding-top: 30px;

    h1 {
        font-size: 32px;
        margin-bottom: 15px
    }

    .sub-text {
        font-size: 22px;
        color: $color-light-grey;
        max-width: 320px;
        margin: 0 auto
    }
}

.error-text {
    font-size: 36px;
    padding-top: 15%;
}

.maxWidth {
    max-width: 100% !important;
}

.user-form-wrap {
    max-width: 429px;
    margin: 0 auto;
    min-height: 450px;
}

.user-form-wrap {
    .form-group {
        position: relative;
        margin-bottom: 28px
    }

    .form-control {
        @include box-shadow(0px 0px 6px rgba(0, 0, 0, 0.10));
        border: 1px solid transparent;
        font-size: $font-size-default;
        color: #000;
        height: 48px;
        padding-left: 24px;
        padding-right: 24px;

        &.has-icon-right {
            padding-right: 50px;
        }

        &:focus {
            border: 1px solid $primary-theme-color;
        }

        &.error {
            border: 1px solid $color-red;
        }

        &.text-grey {
            color: $color-light-grey;
        }

    }

    span.required {
        position: absolute;
        right: 0;
        color: $color-red;
        font-size: 11px;
        padding-top: 4px;
    }

    .button-showpass {
        position: absolute;
        right: 24px;
        top: 12px;
        cursor: pointer;

        svg path {
            fill: #B1BDC9;
        }

        svg line {
            fill: #B1BDC9;
            stroke: #B1BDC9;
        }

        &:hover svg path {
            fill: $primary-theme-color;
        }

        &:hover svg line {
            fill: $primary-theme-color;
            stroke: $primary-theme-color;
        }
    }

    ::placeholder {
        color: $color-xlight-grey;
    }

    :-ms-input-placeholder {
        color: $color-xlight-grey;
    }

    ::-ms-input-placeholder {
        color: $color-xlight-grey;
    }
}

.user-form-wrap {
    .bottom {
        padding-top: 10px;

        .btn {
            width: 160px;
            height: 48px;
            margin-bottom: 25px
        }
    }
}

.forgot-pass-link {
    padding-top: 10px
}

// Influencer Search & Post Analysis
// =====================================

.search-page-graphic {
    background: url(img/page-graphic.svg) no-repeat 0 0;
    position: absolute;
    background-size: 100%;
    width: 100%;
    height: 100%;
    right: -13%;
    top: 100px;
    max-width: 863px;
    max-height: 400px
}

.col.is-search-block {
    padding-top: 80px;
    max-width: 1000px;

    h1 {
        font-size: 32px;
        margin-bottom: 10px
    }

    .sub-text {
        max-width: 680px;
        margin-bottom: 30px
    }

    .form-control {
        @include box-shadow(0px 0px 13px rgba(0, 0, 0, 0.12));
        border: 1px solid transparent;
        font-size: $font-size-default;
        color: #000;
        height: 48px;

        &:focus {
            border: 1px solid $primary-theme-color;
        }

        &.error {
            border: 1px solid $color-red;
        }
    }

    ::placeholder {
        color: $color-xlight-grey
    }

    :-ms-input-placeholder {
        color: $color-xlight-grey
    }

    ::-ms-input-placeholder {
        color: $color-xlight-grey;
    }

    .form-check {
        .styled-checkbox+label {
            padding-left: 30px;
            line-height: 1.7;
        }

        .styled-checkbox+label:before {
            width: 20px;
            height: 20px;

        }

        .styled-checkbox:checked+label:after {
            left: 5px;
            top: 10px;
        }
    }
}

.outer-header {
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.10);
}

.outer-header+.content {
    margin-top: 3px;
}

button:focus span,
button:focus {
    text-decoration: none !important;
}

.width-30 {
    width: 30px;
}

//for removing arrows of numericfield
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.text-search-icon {
    background: url(/static/media/icon-search.4f1e807b.svg) no-repeat 95% 9px #fff;
    padding-right: 25px;

}

.table-grey-background {

    background-color: $color-grey;
}

.table-grey-row {
    width: 100%;
    height: 85px;
}

.btn-text-row {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
}

.records-remaining {
    color: #202020;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.44px;
    min-width: 18%;
    position: absolute;
    left: 0;
    white-space: nowrap;
}

.unlock-btn {
    flex: 0 0 100%;
    text-align: center;
}

.unlock-btn button {
    width: 175px;
    height: 50px;
    border-radius: 4px;
}

.unlock-more-modal-heading {
    height: 31px;
    color: #000000;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 27px;
}

.unlock-more-modal-subheading {

    height: 19px;
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.44px;

    span {
        /* Text style for "12,546" */

        font-weight: 400;
        font-family: $font-grotesk-Regular
    }
}

.no-min-height {
    min-height: auto;
}

.modal-header .modal-title {
    width: 100%;
}

.unlock-button {
    width: 175px;
    height: 50px;
    border-radius: 4px;

}

.posiion-relative {
    position: relative;

}

.modal-header .close {
    position: absolute;
    right: 20px;
}

.toggle-content {
    display: none;
    height: 0;
    overflow: hidden;
    transition: height 350ms ease-in-out;
}

.toggle-content.is-visible {
    display: block;
    height: auto;
}

.blur-bg {
    filter: blur(5px);
}

.social-cloud-loader {

    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1150;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00c2e6;
}

#h-captcha {
    text-align: center !important;
}

//
.copy-filters-arrow {

    z-index: 30;
    position: absolute;
    top: 28px;
    left: 305px;
    color: hsl(0, 0%, 50%);
    font-size: 20px;
    cursor: pointer;

}

.response-svg {
    width: 40%;
    height: auto;
}

//pagination component primary color chanage
.page-item.active .page-link {

    background-color: $primary-theme-color;
    border-color: $primary-theme-color;
}

.page-link {
    color: $primary-theme-color;

}

.page-link:hover {

    color: $primary-theme-color;

}

.page-item.disabled .page-link {
    color: $grey-disabled;
}

.css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    max-width: 284px !important;
}

.logo_pic {
    width: 100px;
    height: auto;
}

// Media Queries
// =====================================

// Mobile
@media only screen and (max-width:992px) {
    .auth-wrap {
        background-size: 100%
    }
}

@media only screen and (max-width:480px) {
    .copy-filters-arrow {
        top: 60px;
        left: 126px;
    }
}

@media (min-width:481px) {
    .copy-filters-arrow {
        left: 306px;
        top: 28px;
    }
}

// @media only screen and (max-width:768px){
// 	.search-and-topic-tensor .search-block {
// 		margin-bottom: 20px
// 	}

// }
@media only screen and (max-width:767px) {
    .edit-and-sort-buttons {
        float: right;
        width: 100%;

        .btn.btn-link {
            padding-left: 0
        }
    }

    .btn.btn-sign-in {
        margin-bottom: 20px;
        margin-top: 5px;
    }

    .col.is-search-block {
        padding-top: 20px;
        padding-bottom: 20px;

        .input-checkbox-wrap,
        .form-control {
            width: 100%;
        }

        .input-group-btn {
            padding-left: 0;
            padding-top: 15px;
            margin-top: 20px;

            .btn {
                margin-left: 0;
                margin-right: 10px;
                width: 135px
            }
        }
    }

    .search-page-graphic {
        right: 0%;
        top: 22%
    }

    .unlock-btn {
        margin-left: 50px;
    }
}

@media only screen and (max-width:479px) {
    .search-wrap .input-group-btn .btn {
        width: 78px;
    }

    .slidecontainer {
        &.dots-wrap span.dot {
            margin: 0px 6px;
        }

        &.letters-wrap span.letter {
            margin: 0px 5px;
        }
    }
}

// Desktop
@media only screen and (min-width:768px) {
    .navbar-wrap {
        height: 70px;

        .navbar-brand {
            margin-left: 5%;
            margin-top: 50px;
        }
    }

    .navbar-expand-md .navbar-nav {
        margin: 0 auto;
        padding-top: 6px;

        a {
            cursor: pointer;
        }
    }

    .navbar-wrap .navbar-light .navbar-nav {
        .nav-link {
            position: relative;
            font-size: 14px;
            padding-bottom: 15px;
            line-height: 37px;

            &.active {
                &:after {
                    content: "";
                    background: $primary-theme-color;
                    height: 2px;
                    position: absolute;
                    width: 100%;
                    max-width: 80px;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    bottom: 0
                }
            }
        }
    }

    .navbar-wrap .navbar-light .nav-item {
        margin: 0 6px;
    }

    // Search and topic tensor {
    .search-and-topic-tensor {
        .col-md-7 {
            padding-right: 8px
        }

        .col-md-5 {
            padding-left: 10px
        }

        // .search-block, .topic-tensor {
        // 	height: 340px;
        // }
    }

    .ts-tag-clouds {
        height: 250px
    }

    // Range Slider

    .range-slider-wrap {
        .slidecontainer {
            padding: 4px 10px 0px 10px
        }
    }

    // Search Results Table

    .results-found {
        padding-bottom: 15px;
        padding-top: 11px;
    }



    .slidecontainer {
        &.letters-wrap {
            margin-top: -8px;
        }

        &.dots-wrap {
            margin-top: -4px
        }
    }

    // Influencer Search & Post Analysis
    // ====================================
    .col.is-search-block {
        h1 {
            font-size: 52px;
            margin-bottom: 20px
        }

        .sub-text {
            margin-bottom: 40px
        }

        .btn {
            margin-left: 4px;

            +.btn {
                margin-left: 12px
            }

        }

        .input-checkbox-wrap {
            width: 100%;
            max-width: 344px
        }
    }

}


@media only screen and (min-width:992px) {
    .navbar-wrap .navbar-light .nav-item {
        margin: 0 24px
    }

    .navbar-wrap .navbar-light .navbar-nav {
        .nav-link {
            font-size: 16px
        }
    }

    .btn.btn-sign-in {
        width: 126px
    }

    // .search-and-topic-tensor {
    // 	.search-block, .topic-tensor {
    // 	height: 305px;
    // 	}
    // }

    .ts-tag-clouds {
        height: 220px;
    }

    // Footer
    .footer-menu-items {
        li {
            margin-left: 48px;
            margin-right: 0
        }
    }

    .range-slider-wrap {
        .col-lg-3 {
            padding-left: 0;
            padding-right: 0
        }
    }

}

.gear-edit-columns {
    position: relative;
}

.gear-edit-columns .ddmenu-col.dropdown-menu.show {
    position: absolute;
    transform: none !important;
    top: 42px !important;
    left: auto !important;
    right: 0;
}


@media only screen and (min-width:1199px) {

    // .search-and-topic-tensor {
    // 	.search-block, .topic-tensor {
    // 	height: 225px;
    // 	}
    // }
    .ts-tag-clouds {
        height: 200px;
    }

    body .container {
        max-width: $container-width;
    }

}

// @media only screen and (min-width:280px) {
//     .filters-wrap {
//         .btn-group {
//             .btn-default {
//                 margin-right: 0;
//             }
//         }
//     }
// }

@media (min-width:576px) and (max-width:767px) {

    .filters-wrap {
        .btn-group.second.col-md-4 {
            .custom-dropdown-column.width-lg {
                left: auto !important;
                right: 22px;
                -webkit-transform: translate3d(8px, 56px, 0px) !important;
                -moz-transform: translate3d(8px, 56px, 0px) !important;
                transform: translate3d(8px, 56px, 0px) !important;
            }

            .custom-dropdown-column.width-lg-1 {
                left: auto !important;
                right: 22px;
                -webkit-transform: translate3d(8px, 56px, 0px) !important;
                -moz-transform: translate3d(8px, 56px, 0px) !important;
                transform: translate3d(8px, 56px, 0px) !important;
            }

            .custom-dropdown-column.width-md {
                left: auto !important;
                right: 22px;
                -webkit-transform: translate3d(8px, 56px, 0px) !important;
                -moz-transform: translate3d(8px, 56px, 0px) !important;
                transform: translate3d(8px, 56px, 0px) !important;
            }
        }
    }
}

@media (min-width:768px) and (max-width:991px) {
    .filters-wrap {
        .btn-group.third.col-md-4 {
            .custom-dropdown-column.width-lg {
                left: auto !important;
                right: 22px;
                -webkit-transform: translate3d(8px, 56px, 0px) !important;
                -moz-transform: translate3d(8px, 56px, 0px) !important;
                transform: translate3d(8px, 56px, 0px) !important;
            }

            .custom-dropdown-column.width-lg-1 {
                left: auto !important;
                right: 22px;
                -webkit-transform: translate3d(8px, 56px, 0px) !important;
                -moz-transform: translate3d(8px, 56px, 0px) !important;
                transform: translate3d(8px, 56px, 0px) !important;
            }

            .custom-dropdown-column.width-md {
                left: auto !important;
                right: 22px;
                -webkit-transform: translate3d(8px, 56px, 0px) !important;
                -moz-transform: translate3d(8px, 56px, 0px) !important;
                transform: translate3d(8px, 56px, 0px) !important;
            }
        }
    }
}

@media (min-width:992px) {
    .filters-wrap {
        .btn-group.sixth.col-md-4 {
            .custom-dropdown-column.width-lg {
                left: auto !important;
                right: 22px;
                -webkit-transform: translate3d(8px, 56px, 0px) !important;
                -moz-transform: translate3d(8px, 56px, 0px) !important;
                transform: translate3d(8px, 56px, 0px) !important;
            }

            .custom-dropdown-column.width-lg-1 {
                left: auto !important;
                right: 22px;
                -webkit-transform: translate3d(8px, 56px, 0px) !important;
                -moz-transform: translate3d(8px, 56px, 0px) !important;
                transform: translate3d(8px, 56px, 0px) !important;
            }

            .custom-dropdown-column.width-md {
                left: auto !important;
                right: 22px;
                -webkit-transform: translate3d(8px, 56px, 0px) !important;
                -moz-transform: translate3d(8px, 56px, 0px) !important;
                transform: translate3d(8px, 56px, 0px) !important;
            }
        }
    }
}

@media only screen and (max-width:480px) {
    .filters-wrap {
        .btn-group {
            .custom-dropdown-column.width-lg {
                width: 280px;

                .row {
                    .col-sm-6 {
                        flex-basis: auto;
                        flex-grow: initial;
                        max-width: 100%;

                        .left-audience {
                            .active-filters-wrap {
                                .active-filters.inside {
                                    li {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .custom-dropdown-column.width-lg-1 {
                width: 280px;

                .row {
                    .col-sm-6 {
                        flex-basis: auto;
                        flex-grow: initial;
                        max-width: 100%;

                        .left-audience {
                            .active-filters-wrap {
                                .active-filters.inside {
                                    li {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.sm-dropdown {
    .custom-dropdown-column.width-lg {
        width: auto !important;
    }

    .select-field-col {
        max-width: 45%;
    }

    .reach {
        .select-field-col {
            max-width: 100%;
        }
    }
}

.location-box .css-2b097c-container {
    min-width: 185px;
}

.inline-filters {
    .active-filters {
        li {
            display: inline-block;
            max-width: 200px;
        }
    }
}

.active-filters {
    .list-inline {
        li {
            display: inline-block;
            width: 100%;
            padding: 0;
            border-radius: 0;

            p {
                height: 38px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding: 10px 15px 5px 5px;
                margin-bottom: 0;
            }
        }
    }

    .list-inline.all-selected-filters {

        li {
            height: auto;
            max-width: 200px;
            padding: 5px 22px 5px 28px;
        }

        // please don't change it before discussion

    }
}


/* surender */



.allInfo {
    padding-top: 20px;
}

.PbInfo,
.cardInfo,
.balanceInfo {
    padding: 10px 20px;
    box-shadow: 0px 0px 10px rgba(76, 76, 76, 0.16);
    border-radius: 4px;

    h3 {
        font-size: 15px;

        span {
            font-size: 12px;
        }
    }
}

.balanceInfo {
    width: 250px;

    .list-group-item {
        border: none;
        padding: .75rem 0;

        span {
            font-weight: 800;
        }

        i {
            float: right;
            color: #333;
        }
    }
}

.PbInfo {
    width: 320px;

    .PlanName {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn {
            width: 130px;
        }
    }
}

.cardInfo {
    width: 300px;
    margin: 0 30px;

    .card-container {
        padding: 0 0px;
        box-shadow: none;
        border-radius: 0;
    }
}

.apiInfo {
    word-wrap: break-word;
    margin-top: 50px;

    .list-group-item {
        border: none;
        padding: 0 0 15px;

    }

    .btn {
        width: 100%;
    }
}

.admin-table {
    .table-hover {
        tbody {
            .hidebottomshadow {
                box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.5);
                border-radius: 6px 6px 0 0 !important;

                &:hover {
                    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.5) !important;
                    border-radius: 6px 6px 0 0 !important;
                }
            }

            .hidetopshadow {
                box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
                border-radius: 0 0 6px 6px !important;
                border-top: 1px solid #fafafa;

                &:hover {
                    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5) !important;
                    border-radius: 0 0 6px 6px !important;
                }

            }
        }
    }
}


@media (min-width: 576px) {
    .container {
        max-width: 960px;
    }
}

.filters-wrap {
    .tooltip-icon {
        top: 0;
    }
}

@media (max-width: 767px) {
    .copy-filters-arrow {
        left: 93%;
    }
}

@media (max-width: 480px) {
    .copy-filters-arrow {
        left: 126px;
    }
}

.search-field-button {
    margin-left: 10px !important;
    width: 160px;
}

.btn {
    transition: inherit !important;
    -webkit-transition: inherit !important;
}

.mw-none {
    max-width: none !important;
}

.tooltip-inner {
    font-size: 11px !important;
}

.mr-left {
    margin-left: -4px;
}

.minwdth-auto {
    min-width: 250px;
}

.user-profile-table {
    .data-values {
        list-style: none;
        padding-left: 0;

        li {
            padding-top: 10px;
            padding-bottom: 10px
        }

        .key {
            color: #8f8f8f;
            font-weight: 700;
        }

    }
}

.outer-header {
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.10);
    -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.10);
    -ms-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.10);
    -o-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.10);
}

.outer-header+.content {
    margin-top: 3px;
}

.background-white {
    background: white;
}

.return-home-btn {
    min-width: 170px !important;
    height: 48px !important;
    margin: auto 10px !important;
}
#header{
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    animation: smoothScroll 1s forwards;
  }
//   .sticky + .content {
//     padding-top: 102px;
//   }
@keyframes smoothScroll {
	0% {
		transform: translateY(-40px);
	}
	100% {
		transform: translateY(0px);
	}
}
