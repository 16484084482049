@charset "UTF-8";
/** Generated by FG **/
@font-face {
  font-family: 'HKGrotesk-Regular';
  src: url("./fonts/HKGrotesk-Regular/HKGrotesk-Regular.eot");
  src: local("☺"), url("./fonts/HKGrotesk-Regular/HKGrotesk-Regular.woff") format("woff"), url("./fonts/HKGrotesk-Regular/HKGrotesk-Regular.ttf") format("truetype"), url("./fonts/HKGrotesk-Regular/HKGrotesk-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

/** Generated by FG **/
@font-face {
  font-family: 'HKGrotesk-Bold';
  src: url("./fonts/HKGrotesk-Bold/HKGrotesk-Bold.eot");
  src: local("☺"), url("./fonts/HKGrotesk-Bold/HKGrotesk-Bold.woff") format("woff"), url("./fonts/HKGrotesk-Bold/HKGrotesk-Bold.ttf") format("truetype"), url("./fonts/HKGrotesk-Bold/HKGrotesk-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

body {
  font-family: 'HKGrotesk-Regular', Arial, "sans-serif" !important;
  font-size: 14px;
  color: #000; }
