// Variables
// =================================

$container-width:1118px;

// colors
$primary-theme-color:#00c2e6;
$color-grey:#F3F6FB;
$color-light-grey:#8896A5;
$color-xlight-grey:#B1BDC9;
$color-white:#fff;
$color-black:#000;
$color-green:#30C62E;
$color-red:#F3211D;
$color-blue: #13BBDB;
$bg-grey:#f3f6fb;

$border-color__light:#d0d0d0;
$tags-background-color:#E1E6EF;

// Shadow
$blocks-shadow:0px 0px 10px rgba(162, 180, 196, 0.16);
$blocks-shadow-dark:0px 0px 10px rgba(162, 180, 196, 0.50);

// mixins
@mixin box-shadow($property) {
    -webkit-box-shadow: $property;
    -ms-box-shadow: $property;
    box-shadow: $property;
}

@mixin border-radius($radius-property) {
    -webkit-border-radius: $radius-property;
    -ms-border-radius: $radius-property;
    border-radius: $radius-property;
}

// Fonts
$font-grotesk-Regular:'HKGrotesk-Regular',
Arial,
"sans-serif";
$font-grotesk-bold:'HKGrotesk-Bold',
Arial,
"sans-serif";

$font-size-small:12px;
$font-size-default:14px;
$font-size-md:16px;

.link-more {
    color: $primary-theme-color;

    &:hover {
        color: $primary-theme-color;
    }
}


// headings
// ==================================

h1,
h2,
h3 {
    font-family: $font-grotesk-bold
}

// Content
// ==================================

.download-button-top {
    margin-top: 10px;

    .btn.btn-outline-primary {
        width: 130px;
    }

    .dropdown-menu {
        min-width: 130px
    }
}

.heading-w-img {
    .image-col {
        float: left;
        margin-right: 20px;
        width: 50px;
        height: 50px;

        img {
            @include border-radius(50%);
            max-width: 100%;
            width: 50px;
            height: 50px;
        }
    }

    .heading {
        overflow: hidden;
        *overflow: visible;
        zoom: 1;
        color: $color-light-grey;

        h1 {
            font-size: 20px;
            margin-bottom: 0;
            color: $color-black;

            span {
                position: relative;
                padding-right: 32px;

                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 1px;
                    width: 20px;
                    height: 20px;
                    background: url(../../img/verified.svg) no-repeat 0 2px;
                    background-size: 18px;
                }
            }
        }

        a {
            color: $primary-theme-color
        }

    }
}

// Tabs section 
// ==================================

.section-inf {
    padding-top: 10px
}

.iap-tabber {
    padding: 10px 0 20px;

    .nav-pills {
        border-bottom: 1px solid $border-color__light
    }

    .nav-pills .nav-link {
        color: $primary-theme-color;
        position: relative;
        margin: 0 15px;
        padding-left: 0;
        padding-right: 0;
        cursor: pointer;

        &.active {
            background: transparent;
            color: $color-black;

            &:after {
                content: "";
                background: $primary-theme-color;
                position: absolute;
                bottom: -1px;
                width: 100%;
                left: 0;
                height: 2px;
            }
        }
    }

}

.graph-wrap {
    text-align: center;

    img {
        max-width: 100%;
        height: auto;
        border-radius: 50%;

    }
}

.row.row-xs {
    margin-left: -10px;
    margin-right: -10px;

    [class*=col-sm-],
    [class*=col-md-],
    .col-6 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.iap-tabber {
    .tab-content {
        padding: 30px 0 0;

        .card {
            margin-bottom: 20px;

            a.card-link {
                height: 300px;
                overflow: hidden;

                .card-img {
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }
    }
}

span.rank-wrap {
    font-size: $font-size-default;
    font-family: $font-grotesk-Regular;
    padding-left: 5px;

    &:before {
        content: "";
        position: relative;
        width: 0;
        height: 0;
        display: inline-block;
        top: -2px;
    }

    &.rank-up {
        color: $color-green;

        &:before {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 6px solid $color-green;
        }
    }

    &.rank-down {
        color: $color-red;
        font-family: $font-grotesk-Regular;

        &:before {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 6px solid $color-red;
        }
    }

    &.rank-neutral {
        color: $color-blue;
        font-family: $font-grotesk-Regular;
    }
}

.card.card-content-custom {
    @include box-shadow($blocks-shadow);
    border: 0;

    h2,
    h3 {
        font-size: 20px;
    }
}

.card.card-content-custom {
    .card-title {
        color: $color-light-grey;
        margin-bottom: 15px
    }

    .bottom {
        margin-top: 20px;

        .btn.btn-link {
            padding: 0;
            color: $primary-theme-color
        }
    }

    .card-list-items {
        .avatar {
            width: 50px;
            height: 50px;
            float: left;
            margin-right: 10px;

            img {
                width: 100%;
                height: 100%;
                @include border-radius(50%);
            }
        }

        .list-content {
            overflow: hidden;
            *overflow: visible;
            zoom: 1;

            a {
                color: $primary-theme-color
            }

            color: $color-light-grey
        }

        li:last-child {
            margin-bottom: 0
        }

        .list-group-item {
            padding: 0;
            border: 0;
            margin-bottom: 15px;

            p {
                margin-bottom: 0;
                font-size: $font-size-small;
            }
        }
    }
}

.total-posts {
    cursor: default;
    font-weight: bold;
    pointer-events: none;
}

.block-last-posts {
    .card-text {
        float: left;
    }
}

.like-comment {
    padding: 0;
    padding-top: 5px;

    li {
        display: inline-block;
        font-size: $font-size-small;
        margin-left: 0;
        margin-right: 10px;
        padding-left: 14px;

        &.like {
            color: $primary-theme-color;
            background: url(../../img/heart-icon.svg) no-repeat 0 2px;
            background-size: 10px;
        }

        &.comment {
            color: $color-red;
            background: url(../../img/comments-icon.svg) no-repeat 0 2px;
            background-size: 10px;
        }
    }
}

.popular-tags {
    ul {
        padding: 0;
        margin-bottom: 0
    }

    li {
        display: inline-block;
        margin-right: 6px;
        margin-bottom: 10px;
        display: inline-block;
        @include border-radius(4px);
        color: $color-black;
        background: $tags-background-color;
        font-size: $font-size-small;
        padding: 5px 10px;
        white-space: nowrap;
    }

    .card.card-content-custom &+.bottom {
        margin-top: 10px
    }
}

.ba-list {
    li {
        display: block;
        border: 0;
        padding: 0;
        margin-bottom: 10px;

        .text-wrap {
            overflow: hidden;
            *overflow: visible;
            zoom: 1;
        }

        .img-col-left {
            float: left;
            width: 35px;
            margin-right: 8px;

            img {
                max-width: 100%
            }
        }
    }
}

.table.modal-table {
    table {
        border-color: $border-color__light
    }

    thead th {
        border-top: 0;
        border-bottom: 1px solid $border-color__light;
        color: $color-light-grey;
        font-size: $font-size-small;
        font-weight: 500;
        padding: 5px
    }

    th,
    td {
        padding: 10px 5px;
        border-top: 1px solid $border-color__light;
        font-size: $font-size-small;
        color: $color-light-grey;
    }

    th {
        color: $color-black
    }

    td {
        vertical-align: middle;
        padding: 5px;

        a {
            color: $primary-theme-color
        }
    }

    .tb-head {
        color: #000;

        h4 {
            margin-bottom: 0
        }

        .country-text-wrap {
            margin-top: 6px
        }
    }
}

.tb-media-left {
    width: 55px !important;
    padding-left: 0 !important;
    padding-right: 5px !important;

    img {
        max-width: 50px
    }
}

// Section Audience
// =====================================
.section {
    padding: 20px 0 0
}

.section-head {
    margin-bottom: 25px;

    h2 {
        font-size: 32px
    }
}

.follower-like-tabs {
    .nav-pills {
        border-bottom: 0;

        .nav-link {
            padding: 5px 15px;
            margin: 0px;
            border: 1px solid $border-color__light;
            width: 100px;
            text-align: center;
            color: $color-black;
            cursor: pointer;

            &:after {
                display: none
            }

            &.active {
                background: $primary-theme-color;
                color: $color-white;
                border: 1px solid $primary-theme-color;
            }
        }

        .nav-item {
            &:first-child a {
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:last-child a {
                border-left: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

    }
}

.icon-unlike,
.icon-liked,
.icon-neutral {
    position: relative;
    padding-left: 30px;

    &:before {
        position: absolute;
        left: 0;
        content: "";
        background-size: 20px;
        width: 20px;
        height: 20px
    }
}

.icon-unlike:before {
    background: url(../../img/dislike.png) no-repeat 0 0;
    background-size: contain;
    top: 4px;
}

.icon-liked:before {
    background: url(../../img/001-like.svg) no-repeat 0 0;
    background-size: contain;
    top: 0
}

.icon-neutral:before {
    background: url(../../img/icon-normal.png) no-repeat 0 0;
    background-size: contain;
    top: 0
}


.custom-bedge-list {
    padding-top: 15px;

    li {
        font-size: $font-size-small + 1;
        padding: 0;
        border: 0;
        margin-bottom: 8px;
        position: relative;
        padding-left: 12px;

        &:before {
            content: "";
            @include border-radius(50%);
            position: absolute;
            width: 6px;
            height: 6px;
            background: $primary-theme-color;
            left: 0
        }

        &.female:before {
            background: $color-red
        }

        .badge {
            padding-right: 0;
        }
    }
}

.multiple-tbl {
    h4 {
        font-size: 16px
    }
}

.country-list {
    li {
        border: 0;
        padding: 0;
        margin-bottom: 8px
    }

    &.with-progress {
        li {
            margin-bottom: 20px
        }
    }
}

.country-text-wrap {
    .location-card-flag {
        width: 60px;
        height: 60px;
        float: left;
        // background: #000;
        margin-right: 8px;

        // border: 2px solid #f0f0f0;
        // margin-top: 1px;
        img {
            max-width: 100%;
            vertical-align: top
        }
    }

    .flag-img {
        width: 22px;
        float: left;
        height: 16px;
        background: #000;
        margin-right: 8px;
        border: 2px solid #f0f0f0;
        margin-top: 1px;

        img {
            max-width: 100%;
            vertical-align: top
        }
    }

    .brand-img {
        border: 0px;
        background: none;
    }

    .country-name {
        overflow: hidden;
        zoom: 1;
        text-overflow: ellipsis;
        white-space: nowrap;

        h2 {
            overflow: hidden;
            zoom: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

}


.progress-bar-custom {
    margin-top: 8px;

    &.progress {
        @include border-radius(0);
        height: 4px;
    }

}

.section-po {
    .react-multi-carousel-list {
        padding-top: 40px;

        .react-multiple-carousel__arrow {
            position: absolute;
            top: 0px;
            left: 50%;
            background: transparent;
            outline: none;
            min-width: 30px;
			min-height: 30px;
			max-width: 30px;


            i {
                font-size: 14px;
                color: $primary-theme-color
            }

            &.react-multiple-carousel__arrow--left {
                margin-left: -26px;

                &:before {
                    content: none;
                }
            }

            &.react-multiple-carousel__arrow--right {
                margin-left: -5px;

                &:before {
                    content: none;
                }
            }

            &:hover {
                i {
                    color: #000
                }
            }
        }

    }

}

.carouselitem {
    padding-left: 10px;
    padding-right: 10px;

    .card {
        @include box-shadow($blocks-shadow-dark);
        border: 0;
        max-width: 300px;
        // a.card-link {
        // 	height: 150px;
        // 	overflow: hidden
        // }
    }

    .post-date {
        font-size: $font-size-small - 2;
        color: $color-light-grey;
        padding-bottom: 8px
    }

    .card-text {
        line-height: 1.4;
        height: 58px;
        // overflow: hidden;
    }

    .post-bottom {
        .text-sm {
            font-size: $font-size-small;
            color: $color-light-grey;
        }

        .text-count {
            font-size: $font-size-default;
            margin-bottom: 0
        }
    }
}

.graph-progress.progress {
    position: absolute;
    height: 7px;
    left: 10px;
    right: 10px;
    // bottom: 44px;
    bottom: 24px;
    border-radius: 5px;

    .progress-bar {
        background: #00C2E6;
        border-radius: 5px;

    }

}

.graph-progress.bottom-44.progress {
    bottom: 44px;
}

.highcharts-stack-labels span.img-graph-bg {
    width: 50px;
    height: 50px;
    background: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 50%;
    border: 1px solid #ddd;
    display: block;
}

// trending-analysis section

.trending-analysis-labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    span {
        color: #666;
    }

}

.trending-analysis-range {
    margin-bottom: 5px;

    span {
        color: #666;
    }

}

.ch-356 {
    height: 356px;
}

.ch-300 {
    height: 300px;
}

.ch-200 {
    height: 200px;
}

.progress.trending-analysis-progress {
    height: 10px;
    border-radius: 5px;

    .trending-analysis-progress-bar {
        border-radius: 5px;
        background: -webkit-linear-gradient(left, #F3211D, #13BBDB, #46CC44) !important;
        /* Chrome10-25,Safari5.1-6 */
    }
}

.progress.trending-analysis-value {
    height: 15px;
    border-radius: 0px;
    background: -webkit-linear-gradient(left, #F3211D, #13BBDB, #46CC44) !important;

    .trending-overlap-div {
        position: inherit;
        background-color: white;
    }

    .trending-overlap-transparent-div {
        background-color: transparent;

    }

    .trending-analysis-progress-bar {
        // border-radius: 5px;
        background: -webkit-linear-gradient(left, #F3211D, #13BBDB, #46CC44) !important;
        /* Chrome10-25,Safari5.1-6 */

    }
}

.custom-nav-icons {
    text-align: center;

    .btn {
        box-shadow: none !important;
        padding: 10px;

        &:hover .fa {
            color: #000
        }
    }

    .fa {
        color: $primary-theme-color
    }
}


// Media Queries
// =====================================

// Desktop
// =====================================

@media only screen and (min-width:576px) {
    .ch-1 {
        height: 125px
    }

    .fix-height {
        height: 193px;
    }

    .ch-2 {
        height: 74px
    }

    .ts-tag-clouds-in {
        height: 357px
    }

    .ch-3 {
        height: 314px
    }

    .ch-12 {
        height: 185px
    }

    .ch-5 {
        height: 190px
    }

    .ch-6 {
        height: 145px
    }

    .ch-179 {
        height: 179px
    }

    .ch-112 {
        height: 112px
    }

    .ch-7 {
        height: 160px
    }

    .ch-8 {
        height: 140px
    }

    .ch-9 {
        height: 177px
    }

    .ch-11 {
        height: 193px
    }

    .ch-14 {
        height: 278px
    }

    .ch-300 {
        height: 300px
    }

    .tb-media-left {
        width: 55px !important;

        img {
            max-width: 100%
        }
    }

    .like-comment {
        float: right;
        padding-top: 0;

        li {
            margin-left: 20px;
            margin-right: 0;
            white-space: nowrap;
        }
    }



    body .modal-dialog {
        max-width: 650px
    }

}

@media only screen and (min-width:767px) {


    .heading-w-img {

        .image-col,
        .image-col img {
            width: 80px;
            height: 80px;
        }

        .heading h1 {
            padding-top: 10px;
            font-size: 32px;

            span:after {
                top: 10px
            }
        }

    }


    .card.card-content-custom {

        h2,
        h3 {
            font-size: 24px
        }
    }

    .ts-tag-clouds-in {
        height: 353px;
    }

    .ch-1 {
        height: 125px
    }

    .ch-3 {
        height: 310px
    }

    .ch-4 {
        height: 210px
    }

    .ch-5 {
        height: 110px
    }

    .ch-10 {
        height: 140px
    }

    .ch-14 {
        height: 153px
    }

    .ch-300 {
        height: 300px
    }

    .table.modal-table {

        th,
        td {
            font-size: 13px
        }
    }

    .multiple-tbl {
        h4 {
            font-size: 18px
        }
    }
}

@media only screen and (min-width:992px) {
    .ch-5 {
        height: 75px
    }
}
